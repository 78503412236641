// src/@chakra-ui/gatsby-plugin/theme.js
import { extendTheme } from "@chakra-ui/react"

const theme = {
  fonts: {
    heading: `Oswald, serif`,
    body: `Lato, sans-serif`,
    mono: `'JetBrains Mono', monospace`,
  },
  styles: {
    global: {
      a: {
        color: "blue.500",
        textDecoration: "underline",
        _hover: {
          textDecoration: "underline",
          color: "blue.400",
        },
        _visited: {
          textDecoration: "underline",
          visited: "blue.600",
        },
      },
    },
  },
  components: {
    Heading: {
      baseStyle: {
        a: {
          color: "inherit",
          textDecoration: "none",
        },
      },
    },
    Tag: {
      baseStyle: {
        label: {
          a: {
            color: "inherit",
            textDecoration: "none",
          },
        },
      },
    },
    NavBlock: {
      baseStyle: {
        a: {
          color: "inherit",
          textDecoration: "none",
        },
        "a.chakra-link": {
          color: "inherit",
          textDecoration: "none",
        },
      },
    },
  },
}

export default extendTheme(theme)
