exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-iocoop-tsx": () => import("./../../../src/pages/iocoop.tsx" /* webpackChunkName: "component---src-pages-iocoop-tsx" */),
  "component---src-pages-projects-tsx": () => import("./../../../src/pages/projects.tsx" /* webpackChunkName: "component---src-pages-projects-tsx" */),
  "component---src-pages-word-category-index-tsx": () => import("./../../../src/pages/word/category/index.tsx" /* webpackChunkName: "component---src-pages-word-category-index-tsx" */),
  "component---src-pages-word-index-tsx": () => import("./../../../src/pages/word/index.tsx" /* webpackChunkName: "component---src-pages-word-index-tsx" */),
  "component---src-pages-word-tag-index-tsx": () => import("./../../../src/pages/word/tag/index.tsx" /* webpackChunkName: "component---src-pages-word-tag-index-tsx" */),
  "component---src-templates-category-tsx": () => import("./../../../src/templates/category.tsx" /* webpackChunkName: "component---src-templates-category-tsx" */),
  "component---src-templates-day-tsx": () => import("./../../../src/templates/day.tsx" /* webpackChunkName: "component---src-templates-day-tsx" */),
  "component---src-templates-month-tsx": () => import("./../../../src/templates/month.tsx" /* webpackChunkName: "component---src-templates-month-tsx" */),
  "component---src-templates-tag-tsx": () => import("./../../../src/templates/tag.tsx" /* webpackChunkName: "component---src-templates-tag-tsx" */),
  "component---src-templates-word-post-tsx": () => import("./../../../src/templates/wordPost.tsx" /* webpackChunkName: "component---src-templates-word-post-tsx" */),
  "component---src-templates-year-tsx": () => import("./../../../src/templates/year.tsx" /* webpackChunkName: "component---src-templates-year-tsx" */)
}

